<template>
  <looker-report :url="url" />
</template>

<script>
export default {
  components: {
    LookerReport: () => import('@/modules/external_bi/components/LookerReport')
  },
  data: () => ({
    url: 'https://lookerstudio.google.com/embed/reporting/7bbe4567-0032-4edb-ab9e-5f4ed1463da1/page/jdknD'
  })
}
</script>
